import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import apiClient from "./FetchCliecnt";
import type { RootState } from "../store";

// Interfaces for each slice's state

interface NewsSubState {
  status: 'idle' | 'loading' | 'error';
  error: string | null;
  NewsSubData: any[];
}

interface BusinessSubState {
  status: 'idle' | 'loading' | 'error';
  error: string | null;
  BusinessSubData: any[];
}

interface ProductSubState {
  status: 'idle' | 'loading' | 'error';
  error: string | null;
  ProductSubdata: any[];
}

interface GmpSubState {
  status: 'idle' | 'loading' | 'error';
  error: string | null;
  GmpSubdata: any[];
}

interface RegulatorySubState {
  status: 'idle' | 'loading' | 'error';
  error: string | null;
  RegulatorySubdata: any[];
}

interface VideoSubState {
  status: 'idle' | 'loading' | 'error';
  error: string | null;
  VideoSubdata: any[];
}

// Initial states
const initialNewsSubState: NewsSubState = {
  status: "idle",
  error: null,
  NewsSubData: [],
};

const initialBusinessSubState: BusinessSubState = {
  status: "idle",
  error: null,
  BusinessSubData: [],
};

const initialProductSubState: ProductSubState = {
  status: "idle",
  error: null,
  ProductSubdata: [],
};

const initialGmpSubState: GmpSubState = {
  status: "idle",
  error: null,
  GmpSubdata: [],
};

const initialRegulatorySubState: RegulatorySubState = {
  status: "idle",
  error: null,
  RegulatorySubdata: [],
};

const initialVideoSubState: VideoSubState = {
  status: "idle",
  error: null,
  VideoSubdata: [],
};

// Async thunks
export const fetchNewsSubData = createAsyncThunk(
  "newsSub/fetchNewsSubData",
  async () => {
    const response = await apiClient.get(`${process.env.REACT_APP_API_URL}/admin/all-sub-category`);
    if (response.data.success) {
      return response.data.data;
    } else {
      throw new Error('Failed to fetch business sub-category');
    }
  }
);


export const fetchBusinessSubData = createAsyncThunk(
  "businessSub/fetchBusinessSubData",
  async () => {
    const response = await apiClient.get(`${process.env.REACT_APP_API_URL}/admin/all-sub-category-Business`);
    if (response.data.success) {
      return response.data.data;
    } else {
      throw new Error('Failed to fetch business sub-category');
    }
  }
);

export const fetchProductSubData = createAsyncThunk(
  "productSub/fetchProductSubData",
  async () => {
    const response = await apiClient.get(`${process.env.REACT_APP_API_URL}/admin/all-sub-category-Product`);
    if (response.data.success) {
      return response.data.data;
    } else {
      throw new Error('Failed to fetch product sub-category');
    }
  }
);

export const fetchGmpSubData = createAsyncThunk(
  "gmpSub/fetchGmpSubData",
  async () => {
    const response = await apiClient.get(`${process.env.REACT_APP_API_URL}/admin/all-sub-category-Gmp`);
    if (response.data.success) {
      return response.data.data;
    } else {
      throw new Error('Failed to fetch GMP sub-category');
    }
  }
);

export const fetchRegulatorySubData = createAsyncThunk(
  "regulatorySub/fetchRegulatorySubData",
  async () => {
    const response = await apiClient.get(`${process.env.REACT_APP_API_URL}/admin/all-sub-category-Regulatory`);
    if (response.data.success) {
      return response.data.data;
    } else {
      throw new Error('Failed to fetch regulatory sub-category');
    }
  }
);

export const fetchVideoSubData = createAsyncThunk(
  "videoSub/fetchVideoSubData",
  async () => {
    const response = await apiClient.get(`${process.env.REACT_APP_API_URL}/admin/all-sub-category-Video`);
    if (response.data.success) {
      return response.data.data;
    } else {
      throw new Error('Failed to fetch video sub-category');
    }
  }
);


const newsSubSlice = createSlice({
  name: "newsSub",
  initialState: initialNewsSubState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchNewsSubData.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(fetchNewsSubData.fulfilled, (state, action) => {
        state.status = 'idle';
        state.NewsSubData = action.payload;
        state.error = null;
      })
      .addCase(fetchNewsSubData.rejected, (state, action) => {
        state.status = 'error';
        state.error = action.error.message ?? "Unknown error";
      });
  },
});



const businessSubSlice = createSlice({
  name: "businessSub",
  initialState: initialBusinessSubState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchBusinessSubData.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(fetchBusinessSubData.fulfilled, (state, action) => {
        state.status = 'idle';
        state.BusinessSubData = action.payload;
        state.error = null;
      })
      .addCase(fetchBusinessSubData.rejected, (state, action) => {
        state.status = 'error';
        state.error = action.error.message ?? "Unknown error";
      });
  },
});


const productSubSlice = createSlice({
  name: "productSub",
  initialState: initialProductSubState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchProductSubData.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(fetchProductSubData.fulfilled, (state, action) => {
        state.status = 'idle';
        state.ProductSubdata = action.payload;
        state.error = null;
      })
      .addCase(fetchProductSubData.rejected, (state, action) => {
        state.status = 'error';
        state.error = action.error.message ?? "Unknown error";
      });
  },
});

const gmpSubSlice = createSlice({
  name: "gmpSub",
  initialState: initialGmpSubState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchGmpSubData.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(fetchGmpSubData.fulfilled, (state, action) => {
        state.status = 'idle';
        state.GmpSubdata = action.payload;
        state.error = null;
      })
      .addCase(fetchGmpSubData.rejected, (state, action) => {
        state.status = 'error';
        state.error = action.error.message ?? "Unknown error";
      });
  },
});

const regulatorySubSlice = createSlice({
  name: "regulatorySub",
  initialState: initialRegulatorySubState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchRegulatorySubData.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(fetchRegulatorySubData.fulfilled, (state, action) => {
        state.status = 'idle';
        state.RegulatorySubdata = action.payload;
        state.error = null;
      })
      .addCase(fetchRegulatorySubData.rejected, (state, action) => {
        state.status = 'error';
        state.error = action.error.message ?? "Unknown error";
      });
  },
});

const videoSubSlice = createSlice({
  name: "videoSub",
  initialState: initialVideoSubState,
  reducers: {},
  extraReducers: (builder) => { 
    builder
      .addCase(fetchVideoSubData.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(fetchVideoSubData.fulfilled, (state, action) => {
        state.status = 'idle';
        state.VideoSubdata = action.payload;
        state.error = null;
      })
      .addCase(fetchVideoSubData.rejected, (state, action) => {
        state.status = 'error';
        state.error = action.error.message ?? "Unknown error";
      });
  },
});

export const newsSubReducer = newsSubSlice.reducer;
export const businessSubReducer = businessSubSlice.reducer;
export const productSubReducer = productSubSlice.reducer;
export const gmpSubReducer = gmpSubSlice.reducer;
export const regulatorySubReducer = regulatorySubSlice.reducer;
export const videoSubReducer = videoSubSlice.reducer;


