import React, { useState } from "react";
import { Link, Navigate } from "react-router-dom";
import "../../components/signup/signup.css";
import emailIcon from "../../assets/Images/user.png";
import passwordImage from "../../assets/Images/password.png";

function Signup() {
  const [email, setEmail] = useState("");
  const [showOtpForm, setShowOtpForm] = useState(false);
  const [otp, setOtp] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isOtpValid, setIsOtpValid] = useState(false);
  const [redirectToHome, setRedirectToHome] = useState(false);

  const handleSendOtp = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/forgetUserPassword`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email }),
        }
      );
      const data = await response.json();
      if (response.ok && data.success) {
        console.log("OTP sent to:", email);
        setShowOtpForm(true);
      } else {
        console.error("Error sending OTP:", data.message);
        alert("Email not found. Please enter a valid email address.");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred while sending OTP.");
    }
  };

  const handleValidateOtp = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/admin/verifyOTPForgetUserPassword`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email, otp }),
        }
      );
      const data = await response.json();
      if (response.ok) {
        console.log("OTP validated successfully:", otp);
        setIsOtpValid(true);
      } else {
        console.error("Error validating OTP:", data.message);
        alert("Invalid OTP. Please try again.");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred while validating OTP.");
    }
  };

  const handleUpdatePassword = async () => {
    if (newPassword !== confirmPassword) {
      alert("Passwords do not match. Please try again.");
      return;
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/admin/forgetUserPasswordUpdate`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email,
            new_password: newPassword,
            confirm_password: confirmPassword,
          }),
        }
      );
      const data = await response.json();
      if (response.ok) {
        alert("Password updated successfully. You can now log in.");
        setRedirectToHome(true); // Set the state to trigger redirect
      } else {
        console.error("Error updating password:", data.message);
        alert("Failed to update password. Please try again.");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred while updating the password.");
    }
  };

  if (redirectToHome) {
    return <Navigate to="/" />;
  }

  return (
    <div className="container backgroundblur">
      {!showOtpForm ? (
        <form className="Form-field-parent float">
          <h2 className="sgnup-heading">Enter Email</h2>
          <hr />
          <div className="form-filed">
            <div className="form_filed-img">
              <img
                src={emailIcon}
                className="input1-icon user1-icon"
                alt="Person Icon"
              />
            </div>
            <input
              className="regi-input-signup"
              type="email"
              id="email"
              name="email"
              placeholder="EMAIL ID"
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="sgn-btn">
            <button type="button" onClick={handleSendOtp}>
              SEND OTP
            </button>
          </div>
        </form>
      ) : (
        <form className="Form-field-parent float">
          {!isOtpValid ? (
            <>
              <h2 className="sgnup-heading">Enter OTP</h2>
              <hr />
              <div className="form-filed">
                <div className="form_filed-img">
                  <img
                    src={emailIcon}
                    className="input1-icon user1-icon"
                    alt="Person Icon"
                  />
                </div>
                <input
                  className="regi-input-signup"
                  type="text"
                  id="otp"
                  name="otp"
                  placeholder="ENTER OTP"
                  required
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                />
              </div>
              <div className="sgn-btn">
                <button type="button" onClick={handleValidateOtp}>
                  VALIDATE OTP
                </button>
              </div>
            </>
          ) : (
            <>
              <h2 className="sgnup-heading">Enter New Password</h2>
              <hr />
              <div className="form-filed">
                <div className="form_filed-img">
                  <img
                    src={passwordImage}
                    className="input1-icon user1-icon"
                    alt="Person Icon"
                  />
                </div>
                <input
                  className="regi-input-signup"
                  type="password"
                  id="newPassword"
                  name="newPassword"
                  placeholder="NEW PASSWORD"
                  required
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                />
              </div>
              <div className="form-filed">
                <div className="form_filed-img">
                  <img
                    src={passwordImage}
                    className="input1-icon user1-icon"
                    alt="Person Icon"
                  />
                </div>
                <input
                  className="regi-input-signup"
                  type="password"
                  id="confirmPassword"
                  name="confirmPassword"
                  placeholder="CONFIRM PASSWORD"
                  required
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
              </div>
              <div className="sgn-btn">
                <button type="button" onClick={handleUpdatePassword}>
                  UPDATE
                </button>
              </div>
              <div className="user1">
                <Link to="/login" className="Signup_link">
                  EXISTING USER?
                </Link>
              </div>
            </>
          )}
        </form>
      )}
    </div>
  );
}

export default Signup;
