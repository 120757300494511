import React, { useEffect, useState } from 'react'
import { resetAllCardData } from "../../Reducers/allSubcategorySlice";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from '../../store';
import { termconditionData } from '../../Reducers/termsConditionSlice';
import { Loader } from "../loader/Loader";
import { setMobileHeadingData } from '../../Reducers/mobileHeadingSlice';


function TermsConditions() {

  const { TermconditionData } = useSelector((state: RootState) => state.temsCondition);

  const [loading, setLoading] = useState(true);
  
  
const dispatch = useDispatch<ThunkDispatch<any, any, any>>();

useEffect(() => {
  setLoading(true);
  dispatch(setMobileHeadingData("Terms&condition"))
  dispatch(termconditionData())
  .then(() => {
    setLoading(false);
  })
  .catch((error) => {
    console.error("Error fetching data:", error);
    setLoading(false);
  });
  dispatch(resetAllCardData());
}, [dispatch]);

  return (
<>
    { loading && <Loader/>}
    <div className="container backgroundblur  ">
      <div
          className="mainHeadAbout"
          style={{
            textAlign: "center",
            fontSize: "30px",
            fontWeight: "700",
            paddingTop: "10px",
          }}
        >
          <span>Terms & Conditions</span>
        </div>
        <div>
          {TermconditionData && TermconditionData?.map((data: any, index: number)=>(
            <div
            key={index}
            className="fontstyl"
            style={{ paddingTop: "10px" }}
            dangerouslySetInnerHTML={{ __html: data.term_condition }}
          />
          ))}
        </div>
    </div>
    </> )
}

export default TermsConditions