import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../store";
import apiClient from "./FetchCliecnt";

interface CategoryState {
  status: 'idle' | 'loading' | 'error';
  error: string;
  CategoryData: any; 
}
const initialState: CategoryState = {
  status: "idle",
  error: "",
  CategoryData: undefined,
};
export const categoryData = createAsyncThunk(
  "allCategory/allCategory",
  async () => {
    try {
      const response = await apiClient.get(
        `${process.env.REACT_APP_API_URL}/admin/all-category`
      );
      if (response.data.success) {
        return response.data; 
      } else {
        throw new Error('Failed to fetch regulatory data');
      }
    } catch (error: any) { 
      return { success: false, error: error.message }; 
    }
  }
);

const allCategorySlice = createSlice({
  name: "category",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(categoryData.pending, (state) => {
        state.status = 'loading'; 
        state.error = ""; 
      })
      .addCase(categoryData.fulfilled, (state, action) => {
       
        state.status = 'idle';
        state.error = ""; 
        state.CategoryData = action.payload; 
       
      })
      .addCase(categoryData.rejected, (state, action) => {
        state.status = 'error'; 
        state.error = action.error.message ?? "Unknown error"; 
      });
  },
});

export default allCategorySlice.reducer;
