import React, { useEffect, useState } from "react";
import HomeImg from "../../assets/Images/Rectangle310.png";
import "../../components/details/details.css";
import PPtImg from "../../assets/Images/ppt-img.png";
import Doctor2 from "../../assets/Images/Group7262.png";
import newsImg from "../../assets/Images/image126.jpg";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import Markdown from 'markdown-to-jsx'
import { AppDispatch, RootState } from "../../store";
import { useDispatch, useSelector } from "react-redux";
import useMediaQuery from '@mui/material/useMediaQuery';
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { detailData } from "../../Reducers/detailsSlice";
import { Loader } from "../loader/Loader";
import ReactPlayer from 'react-player';
import { setMobileHeadingData } from "../../Reducers/mobileHeadingSlice";
import { Height } from "@mui/icons-material";


function Details() {

  const location = useLocation();
  const { selectedNews, prevPage } = location.state || {};
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
 
  const swiperParams = {
    slidesPerView: 1.5,
    loop: true,
    spaceBetween: 10,
    breakpoints: {
      480: {
        slidesPerView: 1.5,
        spaceBetween: 10,
      },
      640: {
        slidesPerView: 1,
        spaceBetween: 10,
      },
      768: {
        slidesPerView: 2,
        spaceBetween: 10,
      },
    },
  };


  const swiperParams2 = {
    slidesPerView: 1.5,
    autoplay: {
      delay: 2000,
      disableOnInteraction: false,
    },
    loop: true,
    spaceBetween: 25,
    breakpoints: {
      480: {
        slidesPerView: 3,
        spaceBetween: -2,
      },
      640: {
        slidesPerView: 1,
        spaceBetween: -2,
      },
      768: {
        slidesPerView: 2,
        spaceBetween: -2,
      },
      992: {
        slidesPerView: 3,
        spaceBetween: -2,
      },
      1200: {
        slidesPerView: 4,
        spaceBetween: 30,
      },
      1400: {
        slidesPerView: 4,
        spaceBetween: 30,
      },
    },
  };

  const [isExpanded, setIsExpanded] = useState(false);

  const toggleReadMore = () => {
    setIsExpanded(!isExpanded);
  };
  const truncateContent = (content: string, length: number) => {
    if (content.length <= length) {
      return content;
    }

    // Find the last space within the desired length
    const truncated = content.substring(0, length);
    const lastSpaceIndex = truncated.lastIndexOf(' ');

    // If there's no space, truncate normally
    if (lastSpaceIndex === -1) {
      return `${truncated}`;
    }

    // Truncate at the last space and add ellipsis
    return `${truncated.substring(0, lastSpaceIndex)}`;
  };


  const handleCardClick = (id: string, prevPage: string) => {
    dispatch(detailData({ id })); // Dispatch detailData with the id
    navigate(`/details/${id}`, { state: { selectedNews, prevPage } });  // Navigate to details page
  };


  const handleCardClick2 = (url: string) => {
    window.open(url, '_blank');
  };


  const dispatch = useDispatch<AppDispatch>();

  const { DetailData } = useSelector((state: RootState) => ({
    DetailData: state.detailPage.DetailData,
  }));
  console.log(DetailData[0]?.pdfurl,"DetailDataDetailData");
  
  const isMobile = useMediaQuery('(max-width: 768px)');

  useEffect(() => {

    dispatch(setMobileHeadingData(prevPage))
    setLoading(true); // Set loading state to true when fetching data

    const fetchData = async () => {
      if (id) {
        await dispatch(detailData({ id }));
      }
      setLoading(false); // Set loading state to false after fetching data
    };

    fetchData();
  }, [dispatch, id]);






 return (
    <>
      {loading && <Loader />}
      <div className="container backgroundblur">
        <div className="row mt-3 mb-3">
          {/* {DetailData.map((data: any, index: number) => ( */}
          {/* <div className="col-12 col-lg-9" key={index}> */}

          {DetailData?.map((data: any, index: number) => (
            <div className="col-12 col-lg-9">
              <div className="custom-line">
                <h6 className="text-dark first-head-details">
                  {data?.title}
                </h6>
                <div className="detail-img-div">
                  <img src={`${process.env.REACT_APP_API_URL}/${data?.news_image[0]?.src}`} className="img-fluid mt-2 detailimg" style={{ width: "100%", borderRadius: "15px" }} /></div>
                <div className="first-para for-text-format">
                  <span>
                    <p className="para-detail" dangerouslySetInnerHTML={{ __html: data.news_content }}>
                    </p>
                  </span>
                  {/* <span>
                    <p className="para-detail" dangerouslySetInnerHTML={{ __html: isExpanded ? data.news_content : truncateContent(data.news_content, 2000) }}></p>
                    {data.news_content.length > 2000 && (
                      <span onClick={toggleReadMore} style={{ color: 'blue', cursor: 'pointer', fontWeight: "700" }}>
                        {isExpanded ? 'Read Less' : ' Read More...'}
                      </span>
                    )}
                  </span> */}
                </div>


              {/* <div className="head1 mt-2">
              <h4 className="second-head">
                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry
              </h4>
            </div> */}


                {/* <div className="second-para for-text-format">
              <span>
              <span className="first-letter">L</span><p className="para-detail">orem ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur
              </p>
              </span>
            </div> */}  
                <div className="mt-2">
                  <Link
                    to={`${process.env.REACT_APP_API_URL}/${data?.news_file[0]?.src || ""}`}
                    style={{ fontSize: "18px", fontWeight: "400" }}
                    className="detail-font detail-Link"
                  >
                    {data?.news_file?.length > 0 ? <i className="bi bi-filetype-pdf">&nbsp;</i> : ""} {data?.news_file[0]?.src}
                  </Link>
                </div>
                {/* <div className="video mt-4">
                  {data?.news_video[0]?.src ? (
                    <iframe
                      className="img-fluid detailimg"
                      src={`${process.env.REACT_APP_API_URL}/${data.news_video[0].src}`}
                      frameBorder="0"
                      allowFullScreen
                      title="Embedded YouTube Video"
                      style={{ height: "520px", width: "100%", paddingRight: "30px" }}
                    ></iframe>
                  ) : (
                    <p>Loading video...</p>
                  )}
                </div> */}

                <div className="video mt-4">
                  {/* {data?.news_video[0]?.src ? (
                    <ReactPlayer
                      className="img-fluid detailimg"
                      url={`${process.env.REACT_APP_API_URL}/${data.news_video[0].src}`}
                      controls
                      width="100%"
                      height="520px"
                      style={{ paddingRight: "30px" }}
                    />   
                  ) : (
                    <p>Loading video...</p>
                  )} */}

                  <div className="detail-img-div">
                    {data?.news_video[0]?.src && (
                      <div className="ppt-viewer\ mt-4">
                        <iframe
                          className="img-fluid detailimg"
                          src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(`${process.env.REACT_APP_API_URL}/${data.news_video[0].src}`)}&action=embedview&slide=1&loop=true`}
                          width="100%"
                          height="520px"
                          style={{ borderRadius: "15px" }}
                          title="PowerPoint Presentation"
                        ></iframe>
                      </div>
                    )}</div>

</div>



{/* <iframe src="https://swapinfotechindore-my.sharepoint.com/personal/sunil_dhangar_swapinfotech_com/_layouts/15/Doc.aspx?sourcedoc={f6bf66a0-b1da-4e61-a063-26c7fc742118}&amp;action=embedview&amp;wdAr=1.7777777777777777" width="476px" height="288px" >This is an embedded <a target="_blank" href="https://office.com">Microsoft Office</a> presentation, powered by <a target="_blank" href="https://office.com/webapps">Office</a>.</iframe> */}
<div >



<center>
  <div className="parent-container">
    {data.pdfurl && (data.pdfurl.startsWith('http://') || data.pdfurl.startsWith('https://')) ? (
      <iframe
        className="pdf-container"
        src={data.pdfurl}
        style={{ width: '100%', height: '500px', border: 'none' }}
      />
    ) : (
      <div
        className="pdf-container"
        dangerouslySetInnerHTML={{ __html: data.pdfurl }}
      />
    )}
  </div>
</center>



</div>
             </div> 
            </div>
          ))} 
         {/* ))}  */}

          <div className="col-12 col-lg-3">
            <div className="related_parent">
              <h3 className="Related_news_details">Related News</h3>
            </div>


            {isMobile ? (
              <Swiper {...swiperParams}>
                {selectedNews?.slice(0, 6).map((data: any, index: number) => (
                  <SwiperSlide key={index} className="fixed-width-slide">
                    <div className="card custom-border-shadow" onClick={() => {
                      if (data.name === "Internal News" || data.name === "Internal Product" || data.name === "Internal GMP" || data.name === "Internal Regulatory" || data.name === "Internal Business" || data.name === "Internal Videos") {
                        handleCardClick(data._id, prevPage);
                      }
                      else {
                        handleCardClick2(data.link)
                      }
                    }}>
                      <div className="custom-img-parent">
                        <img
                          src={`${process.env.REACT_APP_API_URL}/${data.news_image[0]?.src}`}
                          className="card-img-top-details"

                        />
                        <div className="image-lineae-gradient"></div>
                        <div className="img-absolute">
                          <div className="leftImg-details">
                            {data.logo_image.length > 0 ? (
                              <img src={`${process.env.REACT_APP_API_URL}/${data.logo_image[0]?.src}`} 
                              className="src" />
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="newschanel">
                            <span className="text-light">{data.logo_title && data.logo_title.split(' ').slice(0, 2).join(' ').toUpperCase()}</span>
                          </div>
                        </div>
                      </div>
                      <div className="card-body card-body-detail">
                        <span className="card-text card-text-detail">
                          {data.title}
                        </span>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            ) : (
              <div className="row">
                {selectedNews?.slice(0, 6).map((data: any, index: number) => (
                  <div className="col-12 col-md-6 col-lg-12" key={index}>
                    <div className="card custom-border-shadow" onClick={() => {
                      if (data.name === "Internal News" || data.name === "Internal Product" || data.name === "Internal GMP" || data.name === "Internal Regulatory" || data.name === "Internal Business" || data.name === "Internal Videos") {
                        handleCardClick(data._id, prevPage);
                      }
                      else {
                        handleCardClick2(data.link)
                      }
                    }}>
                      <div className="custom-img-parent">
                        <img src={`${process.env.REACT_APP_API_URL}/${data.news_image[0]?.src}`} className="card-img-top" />
                        <div className="image-lineae-gradient"></div>
                        <div className="img-absolute">
                          <div className="leftImg-detail">
                            {data.logo_image.length > 0 ? (
                              <img src={`${process.env.REACT_APP_API_URL}/${data.logo_image[0]?.src}`} className="src" />
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="newschanel">
                            <span className="text-light"> {data.logo_title && data.logo_title.split(' ').slice(0, 2).join(' ').toUpperCase()}</span>
                          </div>
                        </div>
                      </div>
                      <div className="card-body">
                        <p className="card-text card-text-detail">
                          {data.title}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </>);
}

export default Details;
