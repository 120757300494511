import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../store";
import apiClient from "./FetchCliecnt";

interface DisclaimerState {
  status: 'idle' | 'loading' | 'error';
  error: string;
  DisclaimerData: any[]; 
}
const initialState: DisclaimerState = {
  status: "idle",
  error: "",
  DisclaimerData: [],
};
export const disclaimerData = createAsyncThunk(
  "Disclaimer/Disclaimer",
  async () => {
    try {
      const response = await apiClient.get(
        `${process.env.REACT_APP_API_URL}/admin/all-disclaimer`
      );
      if (response.data.success) {
        return response.data; 
      } else {
        throw new Error('Failed to fetch regulatory data');
      }
    } catch (error: any) {  
      return { success: false, error: error.message }; 
    }
  }
);

const disclaimerSlice = createSlice({
  name: "disclaimer",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(disclaimerData.pending, (state) => {
        state.status = 'loading'; 
        state.error = ""; 
      })
      .addCase(disclaimerData.fulfilled, (state, action) => {
        state.status = 'idle';
        state.error = ""; 
        state.DisclaimerData = action.payload.data; 
      })
      .addCase(disclaimerData.rejected, (state, action) => {
        state.status = 'error'; 
        state.error = action.error.message ?? "Unknown error"; 
      });
  },
});

export default disclaimerSlice.reducer;
