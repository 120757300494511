import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import apiClient from "./FetchCliecnt";
import type { RootState } from "../store";

interface ProductState {
  status: 'idle' | 'loading' | 'error';
  error: string;
  ProductData: any[]; 
}

const initialState: ProductState = {
  status: "idle",
  error: "",
  ProductData: [],
};

export const productData = createAsyncThunk(
    "product/product",
    async () => {
      console.log('Fetching about us page...');
      try {
        const response = await apiClient.get(
          `${process.env.REACT_APP_API_URL}/All-Product-active`,
        );
        if (response.data.success) {
          const filteredData = response.data.data.filter((news: any) => news.status === 'active');
          return filteredData;
        } else {
          throw new Error('Failed to fetch about us page');
        }
      } catch (error: any) {
        return { success: false, error: error.message };  
      }
    }
  );

const productSlice = createSlice({
    name: "product",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(productData.pending, (state) => {
          state.status = 'loading'; 
          state.error = ""; 
        })
        .addCase(productData.fulfilled, (state, action) => {
          state.status = 'idle';
          state.error = ""; 
          state.ProductData = action.payload; 
        })
        .addCase(productData.rejected, (state, action) => {
          state.status = 'error'; 
          state.error = action.error.message ?? "Unknown error"; 
        });
    },
  });
  

export const userList = (state: RootState) => state.auth;
export default productSlice.reducer;
