import { createSlice } from "@reduxjs/toolkit";

const initialAlldataState = {
  status: "idle",
  error: null,
  AlldataData: [],
};

const allDataSlice = createSlice({
  name: "alldata",
  initialState: initialAlldataState,
  reducers: {
    setAllData: (state, action) => {
      state.AlldataData = action.payload;
      state.status = 'idle';
      state.error = null;
      
    },
    setLoading: (state) => {
      state.status = 'loading';
      state.error = null;
    },
    setError: (state, action) => {
      state.status = 'error';
      state.error = action.payload;
    },
  },
});

export const { setAllData, setLoading, setError } = allDataSlice.actions;

export const allDataReducer = allDataSlice.reducer;
