import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../store";
import apiClient from "./FetchCliecnt";

interface BusinessState {
  status: 'idle' | 'loading' | 'error';
  error: string;
  BusinessData: any[]; 
}
const initialState: BusinessState = { 
  status: "idle",
  error: "",
  BusinessData: [],
};
export const businessData = createAsyncThunk(
  "business/business",
  async () => {
    try {
      const response = await apiClient.get(
        `${process.env.REACT_APP_API_URL}/All-Business-active`
      );
      if (response.data.success) {
        const filteredData = response.data.data.filter((news: any) => news.status === 'active');
        return filteredData;
      } else {
        throw new Error('Failed to fetch regulatory data');
      }
    } catch (error: any) { 
      return { success: false, error: error.message }; 
    }
  }
);

const businessSlice = createSlice({
  name: "regulatory",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(businessData.pending, (state) => {
        state.status = 'loading'; 
        state.error = ""; 
      })
      .addCase(businessData.fulfilled, (state, action) => {
        state.status = 'idle';
        state.error = ""; 
        state.BusinessData = action.payload; 
      })
      .addCase(businessData.rejected, (state, action) => {
        state.status = 'error'; 
        state.error = action.error.message ?? "Unknown error"; 
      });
  },
});

export default businessSlice.reducer;
