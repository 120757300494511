// src/redux/reducers/mobileHeadingSlice.js (or similar path)
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface MobileHeadingState {
  mobileHeadingData: string;
}

const initialState: MobileHeadingState = {
  mobileHeadingData: "",
};

const mobileHeadingSlice = createSlice({
  name: 'MobileHeadingState',
  initialState,
  reducers: {
    setMobileHeadingData: (state, action: PayloadAction<string>) => {
      state.mobileHeadingData = action.payload;
    }
  }
});

export const { setMobileHeadingData } = mobileHeadingSlice.actions;

export default mobileHeadingSlice.reducer;
