import { createSlice, createAsyncThunk, isAnyOf } from "@reduxjs/toolkit";
import apiClient from "./FetchCliecnt";
import type { RootState } from "../store";
import { createAction } from "@reduxjs/toolkit";


interface AllCard {
  status: 'idle' | 'loading' | 'error';
  error: string | null;
  AllCarddata: any[];
}


// Initial states
const initialAllCardState: AllCard = {
  status: "idle",
  error: null,
  AllCarddata: [],
};




// Async thunks

// Action to reset AllCarddata
// export const resetAllCardData = createAction("allCard/resetAllCardData");

// Thunk action creator to dispatch resetAllCardData action
// export const resetAllCardDataAction = () => {
//   return (dispatch) => {
//     dispatch(resetAllCardData());
//   };
// };

export const fetchNewsSubDataMobile = createAsyncThunk(
  "news/fetchNewsSubData",
  async () => {
    const response = await apiClient.get(`${process.env.REACT_APP_API_URL}/admin/all-sub-category`);
    if (response.data.success) {
      return response.data.data;
    } else {
      throw new Error('Failed to fetch business sub-category');
    }
  }
);


export const fetchBusinessSubDataMobile = createAsyncThunk(
  "business/fetchBusineData",
  async () => {
    const response = await apiClient.get(`${process.env.REACT_APP_API_URL}/admin/all-sub-category-Business`);
    if (response.data.success) {
      return response.data.data;
    } else {
      throw new Error('Failed to fetch business sub-category');
    }
  }
);

export const fetchProductSubDataMobile = createAsyncThunk(
  "product/fetchProductData",
  async () => {
    const response = await apiClient.get(`${process.env.REACT_APP_API_URL}/admin/all-sub-category-Product`);
    if (response.data.success) {
      return response.data.data;
    } else {
      throw new Error('Failed to fetch product sub-category');
    }
  }
);

export const fetchGmpSubDataMobile = createAsyncThunk(
  "gmp/fetchGmpData",
  async () => {
    const response = await apiClient.get(`${process.env.REACT_APP_API_URL}/admin/all-sub-category-Gmp`);
    if (response.data.success) {
      return response.data.data;
    } else {
      throw new Error('Failed to fetch GMP sub-category');
    }
  }
);

export const fetchRegulatorySubDataMobile = createAsyncThunk(
  "regulatory/fetchRegulatoryData",
  async () => {
    const response = await apiClient.get(`${process.env.REACT_APP_API_URL}/admin/all-sub-category-Regulatory`);
    if (response.data.success) {
      return response.data.data;
    } else {
      throw new Error('Failed to fetch regulatory sub-category');
    }
  }
);

export const fetchVideoSubDataMobile = createAsyncThunk(
  "video/fetchVideoData",
  async () => {
    const response = await apiClient.get(`${process.env.REACT_APP_API_URL}/admin/all-sub-category-Video`);
    if (response.data.success) {
      return response.data.data;
    } else {
      throw new Error('Failed to fetch video sub-category');
    }
  }
);

const allCardSlice = createSlice({
    name: "allCard",
    initialState: initialAllCardState,
    reducers: {
        resetAllCardData: (state) => {
            state.AllCarddata = []; // Reset AllCarddata to an empty array
          },
    },
    extraReducers: (builder) => {
      builder
      .addCase(fetchNewsSubDataMobile.fulfilled, (state, action) => {
        state.AllCarddata = action.payload;
        state.status = 'idle';
        state.error = null;
      })
        .addCase(fetchBusinessSubDataMobile.fulfilled, (state, action) => {
          state.AllCarddata = action.payload;
          state.status = 'idle';
          state.error = null;
        })
        .addCase(fetchProductSubDataMobile.fulfilled, (state, action) => {
          state.AllCarddata = action.payload;
          state.status = 'idle';
          state.error = null;
        })
        .addCase(fetchGmpSubDataMobile.fulfilled, (state, action) => {
          state.AllCarddata = action.payload;
          state.status = 'idle';
          state.error = null;
        })
        .addCase(fetchRegulatorySubDataMobile.fulfilled, (state, action) => {
 
          state.AllCarddata = action.payload;
          state.status = 'idle';
          state.error = null;
        })
        .addCase(fetchVideoSubDataMobile.fulfilled, (state, action) => {
          state.AllCarddata = action.payload;
          state.status = 'idle';
          state.error = null;
        })
        .addMatcher(
          isAnyOf(
            fetchBusinessSubDataMobile.pending,
            fetchProductSubDataMobile.pending,
            fetchGmpSubDataMobile.pending,
            fetchRegulatorySubDataMobile.pending,
            fetchVideoSubDataMobile.pending
          ),
          (state) => {
            state.status = 'loading';
            state.error = null;
          }
        )
        .addMatcher(
          isAnyOf(
            fetchBusinessSubDataMobile.rejected,
            fetchProductSubDataMobile.rejected,
            fetchGmpSubDataMobile.rejected,
            fetchRegulatorySubDataMobile.rejected,
            fetchVideoSubDataMobile.rejected
          ),
          (state, action) => {
            state.status = 'error';
            state.error = action.error.message ?? 'Failed to fetch data';
          }
        );
    },
  });

  export const { resetAllCardData } = allCardSlice.actions;
export const allCardReducer = allCardSlice.reducer;


