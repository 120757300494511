import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface PillBgColorState {
  [key: string]: boolean;
  show: boolean;
  pgcoloreMore: boolean;
}

const initialState: PillBgColorState = {
  hello: true,
  show: false,
  pgcoloreMore: false,
};

const pillBgColorSlice = createSlice({
  name: 'pillBgColor',
  initialState,
  reducers: {
    setPillBgColor(state, action: PayloadAction<{ [key: string]: boolean }>) {
      // Clear existing keys
      Object.keys(state).forEach(key => {
        if (key !== 'show' && key !== 'pgcoloreMore') {
          state[key] = false;
        }
      });

      // Set new keys from payload
      Object.keys(action.payload).forEach(key => {
        state[key] = action.payload[key];
      });
    },
    resetPillBgColor(state) {
      Object.keys(state).forEach(key => {
        if (key !== 'show' && key !== 'pgcoloreMore') {
          state[key] = false;
        }
      });
    },
    setShow(state, action: PayloadAction<boolean>) {
      state.show = action.payload;
    },
    setPgcoloreMore(state, action: PayloadAction<boolean>) {
      state.pgcoloreMore = action.payload;
    },
  }
});

export const { setPillBgColor, resetPillBgColor, setShow, setPgcoloreMore } = pillBgColorSlice.actions;

export default pillBgColorSlice.reducer;
