import React, { useEffect, useState } from "react";
import "../../components/login/login.css";
import emailIcon from "../../assets/Images/user.png";
import passwordImage from "../../assets/Images/password.png";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../hook";
import { login, userLogin2 } from "../../Reducers/authSlice";
import { Link, NavLink, useNavigate } from "react-router-dom";

import Swal from "sweetalert2";
import { resetAllCardData } from "../../Reducers/allSubcategorySlice";
import { setMobileHeadingData } from "../../Reducers/mobileHeadingSlice";


function Login() {
  const dispatch = useDispatch<any>();
  const auth = useAppSelector((state: any) => state.auth);
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const navigate = useNavigate();

  const handleverify = (event: React.FormEvent) => {
    event.preventDefault();

    const body: any = {
      email: email,
      password: password,
    };
    dispatch(userLogin2(body))
      .unwrap()
      .then((res: any) => {
        localStorage.setItem("userInfo", JSON.stringify(res.userInfo));
        dispatch(login());
        const redirectUrl = localStorage.getItem("redirectUrl");

        Swal.fire({
          icon: "success",
          title: "Login Successfully",
        });
        navigate('/');
      })
      .catch((error: any) => {
        Swal.fire({
          icon: "error",
          title: "Login Failed",
          text:  error.message,
        });
      });
  };

  const handleEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };
  const handlePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  useEffect(() => {


    dispatch(setMobileHeadingData("Login"))
    dispatch(resetAllCardData());


}, [dispatch]);

  return (
    <>
      <div className="container backgroundblur">
        <form className="form-container" onSubmit={handleverify}>
          <h2 className="lgn-heading">Login</h2>
          <hr />
          <div className="input-container">
            <div>
              <input
                className="regi-input"
                type="text"
                id="username"
                name="username"
                placeholder="Username"
                onChange={handleEmail}
              />
              <img
                src={emailIcon}
                className="input1-icon user1-icon"
                alt="Email Icon"
              />
            </div>
          </div>
          <div className="input-container">
            <img
              src={passwordImage}
              className="input1-icon user1-icon"
              alt="Lock Icon"
            />
            <input
              className="regi-input"
              type="password"
              id="password"
              name="password"
              placeholder="Password"
              onChange={handlePassword}
              required
            />
          </div>
          <div className="forgot-password">
            <Link to="/forget" className="user_link" style={{fontWeight:700}}>
              Forgot Password?
            </Link>
          </div>
          <div className="lgn-btn">
            <button type="submit">Login</button>
          </div>
          <div className="user text-center mt-4 userset">
            <Link to="/signup" className="user_link">
              New User?
            </Link>
          </div>
        </form>
      </div>
    </>
  );
}

export default Login;
