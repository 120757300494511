// Reducers/fillerArraySlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface FillerArrayState {
  fillerArray: any[];
}

const initialState: FillerArrayState = {
  fillerArray: [],
};

const fillerArraySlice = createSlice({
  name: 'fillerArray',
  initialState,
  reducers: {
    setFillerArray(state, action: PayloadAction<any[]>) {
      state.fillerArray = action.payload;
    },
    clearFillerArray(state) {
      state.fillerArray = [];
    },
  },
});

export const { setFillerArray, clearFillerArray } = fillerArraySlice.actions;
export default fillerArraySlice.reducer;
