import React, { useEffect, useState } from "react";
import "../../components/disclaimer/disclaimer.css";
import { resetAllCardData } from "../../Reducers/allSubcategorySlice";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { disclaimerData } from "../../Reducers/disclaimerSlice";
import { Loader } from "../loader/Loader";
import { setMobileHeadingData } from "../../Reducers/mobileHeadingSlice";

function Disclaimer() {

  const [loading, setLoading] = useState(true);
  const { DisclaimerData } = useSelector((state: RootState) => state.disclaimer);
  
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
useEffect(() => {
  setLoading(true);
  dispatch(setMobileHeadingData("Disclaimer"))
  dispatch(disclaimerData())
  .then(() => {
    setLoading(false);
  })
  .catch((error) => {
    console.error("Error fetching data:", error);
    setLoading(false);
  });
  dispatch(resetAllCardData());
}, [dispatch]);

console.log("this is the data of the para tag",DisclaimerData)

  return (
    <>
    { loading && <Loader/>}
    <div className="container  backgroundblur">
      <div
          className="mainHeadAbout"
          style={{
            textAlign: "center",
            fontSize: "30px",
            fontWeight: "700",
            paddingTop: "10px",
          }}
        >
          <span>Disclaimer</span>
        </div>
        <div>
          {DisclaimerData && DisclaimerData?.map((data: any, index: number)=>(
            <div
            key={index}
            className="fontstyl"
            style={{ paddingTop: "10px" }}
            dangerouslySetInnerHTML={{ __html: data.disclaimer }}
          />
          ))}
        </div>
    </div>
    </> );
}

export default Disclaimer;
