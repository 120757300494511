import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../store";
import apiClient from "./FetchCliecnt";

interface RegulatoryState {
  status: 'idle' | 'loading' | 'error';
  error: string;
  RegulatoryData: any[]; 
}

const initialState: RegulatoryState = {
  status: "idle",
  error: "",
  RegulatoryData: [],
};
export const regulatoryData = createAsyncThunk(
  "regulatory/regulatory",
  async () => {
    try {
      const response = await apiClient.get(
        `${process.env.REACT_APP_API_URL}/All-Regulatory-active`
      );
      if (response.data.success) {
        const filteredData = response.data.data.filter((news: any) => news.status === 'active');
        return filteredData;
      } else {
        throw new Error('Failed to fetch regulatory data');
      }
    } catch (error: any) { 
      return { success: false, error: error.message }; 
    }
  }
);

const regulatorySlice = createSlice({
  name: "regulatory",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(regulatoryData.pending, (state) => {
        state.status = 'loading'; 
        state.error = ""; 
      })
      .addCase(regulatoryData.fulfilled, (state, action) => {
        state.status = 'idle';
        state.error = ""; 
        state.RegulatoryData = action.payload; 
      })
      .addCase(regulatoryData.rejected, (state, action) => {
        state.status = 'error'; 
        state.error = action.error.message ?? "Unknown error"; 
      });
  },
});

export default regulatorySlice.reducer;
