// src/utils/handleTabClick.ts
import { Dispatch } from "redux";
import { setPillBgColor } from "../Reducers/pillBgColorSlice";
import { setFillerArray } from "../Reducers/fillerArraySlice";

interface HandleTabClickParams<T> {
  tab: string;
  subCategoryName?: string;
  data: T[];
  dispatch: Dispatch;
}

export const handleTabClick = <T>({
  tab,
  subCategoryName,
  data,
  dispatch,
}: HandleTabClickParams<T>) => {
  dispatch(setPillBgColor({ [tab]: true }));
  const filteredData = subCategoryName
    ? data.filter((item: T) =>
        (item as any).sub_category_name.includes(subCategoryName)
      )
    : data;
  dispatch(setFillerArray(filteredData));
};
