import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../store";
import apiClient from "./FetchCliecnt";

interface TermconditionState {
  status: 'idle' | 'loading' | 'error';
  error: string;
  TermconditionData: any[]; 
}
const initialState: TermconditionState = {
  status: "idle",
  error: "",
  TermconditionData: [],
};
export const termconditionData = createAsyncThunk(
  "Termcondition/Termcondition",
  async () => {
    try {
      const response = await apiClient.get(
        `${process.env.REACT_APP_API_URL}/admin/all-term-condition`
      );
      if (response.data.success) {
        return response.data; 
      } else {
        throw new Error('Failed to fetch regulatory data');
      }
    } catch (error: any) {  
      return { success: false, error: error.message }; 
    }
  }
);

const termsConditionSlice = createSlice({
  name: "Termcondition",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(termconditionData.pending, (state) => {
        state.status = 'loading'; 
        state.error = ""; 
      })
      .addCase(termconditionData.fulfilled, (state, action) => {
        state.status = 'idle';
        state.error = ""; 
        state.TermconditionData = action.payload.data; 
      })
      .addCase(termconditionData.rejected, (state, action) => {
        state.status = 'error'; 
        state.error = action.error.message ?? "Unknown error"; 
      });
  },
});

export default termsConditionSlice.reducer;
